import { Grid } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { InView } from "react-intersection-observer";
import { TemplateCard } from "./TemplateCard";
import { GridContainer } from "../Reusable/GridContainer";
import { HeadingsContent } from "../Reusable/HeadingsContent";

import temaGenerico from "../../assets/images/templates/tema-generico.webp";
import temaMasculino from "../../assets/images/templates/tema-masculino.webp";
import temaFeminino from "../../assets/images/templates/Tema-Feminino.webp";
import temaInfantil from "../../assets/images/templates/lojakids.webp";

export const TemplatesSection = () => {
  return (
    <GridContainer bgColor="grey.100">
      <Helmet>
        <title>PlayDrop - LandingPage - Seção de templates da plataforma</title>
        <meta
          name="description"
          content="Seção de templates personalizados da plataforma PlayDrop"
        />
      </Helmet>
      <Grid
        w="100%"
        alignItems="center"
        justifyContent="center"
        m={["0", "0", "0", "0 auto"]}
        pt={["0", "0", "0", "6.5rem"]}
        pb={["0", "0", "0", "6.5rem"]}
        pr={["1rem", "1rem", "1rem", "0"]}
        pl={["1rem", "1rem", "1rem", "0"]}
      >
        <HeadingsContent
          margin_bottom="0"
          title="Nossos temas personalizados"
          text="Nunca foi tão fácil criar a sua loja profissional. Através de nossos temas personalizados da Shopify, você irá ter ainda mais agilidade em sua criação"
        />
        <InView>
          {({ ref, inView }) => (
            <Grid
              w="100%"
              m="0 auto"
              maxW="90rem"
              ref={ref}
              transition="transform 1s ease"
              transform={`translateY(${inView ? "0" : "100px"})`}
            >
              <Grid
                gap="10"
                w="100%"
                m="1rem auto 0 auto"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(500px, 1fr))"
              >
                <TemplateCard
                  src={temaGenerico}
                  title="Tema Genérico"
                  alt="Imagem contendo tema genérico"
                  href="https://generica-playdrop.myshopify.com/"
                />
                <TemplateCard
                  src={temaMasculino}
                  title="Tema Masculino"
                  alt="Imagem contendo tema masculino"
                  href="https://playdropnichada.myshopify.com"
                />
                <TemplateCard
                  src={temaFeminino}
                  title="Tema Feminino"
                  alt="Imagem contendo tema Ella"
                  href="https://loja-feminina-playdrop.myshopify.com"
                />
                <TemplateCard
                  src={temaInfantil}
                  title="Tema Infantil"
                  alt="Imagem contendo tema infantil"
                  href="https://infantilplaydrop.myshopify.com/"
                />
              </Grid>
            </Grid>
          )}
        </InView>
      </Grid>
    </GridContainer>
  );
};
