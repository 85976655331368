import { theme } from "../../styles";
import { Header } from "../../components/Header";
import { planData } from "./planData";
import { BsX, BsCheckLg } from "react-icons/bs";
import {
  Box,
  Flex,
  Grid,
  Text,
  Link,
  Center,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";

import { InView } from "react-intersection-observer";
import { CommonQuestionsSection } from "../../components/CommonQuestionsSection";
import { currencyFormat } from "../../utils/currencyFormat";

export const Plans = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Grid w="100%" mt="6.3rem" bg="grey.400" overflowY="hidden">
      <Header />
      <InView>
        {({ ref, inView }) => (
          <Grid
            w="100%"
            ref={ref}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <Grid
              w="100%"
              display="flex"
              flexDir="column"
              alignItems="center"
              transition="transform 1s ease"
              transform={
                !isWideVersion
                  ? `translateY(${inView ? "0" : "100%"})`
                  : `translateY(${inView ? "0" : "100px"})`
              }
            >
              <Heading
                as="h1"
                mt="3rem"
                mb="2rem"
                color="black.600"
                textAlign="center"
                fontSize={["3xl", "3xl", "4xl"]}
              >
                Planos PlayDrop
              </Heading>
              <Heading
                as="h3"
                w="100%"
                mb="2rem"
                maxW="500px"
                color="grey.700"
                textAlign="center"
                fontSize={["lg", "lg", "xl"]}
              >
                Chegou o momento de selecionar o melhor plano para o seu sucesso
                no E-commerce.
              </Heading>
            </Grid>
            <Grid w="100%" p="1rem" m="0 auto" maxW="80rem">
              <Grid
                gap="10"
                w="100%"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
              >
                {planData.map((plan) => (
                  <Grid
                    p="8"
                    display="flex"
                    flexDir="column"
                    key={plan.planId}
                    borderRadius="1rem"
                    border="2px solid #F2F2F2"
                    transition="transform 2s ease"
                    bg={
                      plan.planTitle === "Plano Premium"
                        ? theme.colors.primary[500]
                        : theme.colors.white[600]
                    }
                    transform={
                      !isWideVersion
                        ? `translateY(${inView ? "0" : "100%"})`
                        : `translateY(${inView ? "0" : "100px"})`
                    }
                  >
                    {plan.planTitle === "Plano Premium" && (
                      <Box
                        p="2"
                        fontSize="xs"
                        color="black.600"
                        bg="secondary.300"
                        borderRadius="7px"
                        m="0 auto 1rem auto"
                      >
                        Mais vendido
                      </Box>
                    )}
                    <Heading
                      mb="6"
                      fontSize="2xl"
                      fontWeight="800"
                      textAlign="center"
                      color={
                        plan.planTitle === "Plano Premium"
                          ? theme.colors.grey[500]
                          : theme.colors.primary[500]
                      }
                    >
                      {plan.planTitle}
                    </Heading>
                    <Grid display="flex" flexDir="column">
                      {plan.planTitle !== "Plano Basic" && (
                        <Text
                          color={
                            plan.planTitle === "Plano Premium"
                              ? theme.colors.grey[500]
                              : theme.colors.black[600]
                          }
                          fontSize="xs"
                          fontWeight="400"
                          textAlign="center"
                        >
                          A partir de:
                        </Text>
                      )}
                      <Flex
                        mb="1.5rem"
                        alignItems="baseline"
                        justifyContent="center"
                      >
                        <Text
                          fontSize="2xl"
                          fontWeight="700"
                          color={
                            plan.planTitle === "Plano Premium"
                              ? theme.colors.grey[500]
                              : theme.colors.black[600]
                          }
                        >
                          {currencyFormat(plan.planPrice)}
                        </Text>
                        <Text
                          ml="2"
                          fontSize="md"
                          color={
                            plan.planTitle === "Plano Premium"
                              ? theme.colors.grey[500]
                              : theme.colors.black[600]
                          }
                        >
                          / mês
                        </Text>
                      </Flex>
                    </Grid>
                    <Grid gap="6">
                      {plan.benefitsIncluded.map((benefit) => (
                        <Flex gap="4" key={benefit} alignItems="baseline">
                          <Center>
                            <BsCheckLg
                              size="1.2rem"
                              fill={
                                plan.planTitle === "Plano Premium"
                                  ? theme.colors.grey[500]
                                  : theme.colors.success[600]
                              }
                            />
                          </Center>
                          <Text
                            fontSize="md"
                            color={
                              plan.planTitle === "Plano Premium"
                                ? theme.colors.grey[500]
                                : theme.colors.black[600]
                            }
                          >
                            {benefit}
                          </Text>
                        </Flex>
                      ))}
                      {plan.benefitsNotIncluded?.map((benefit) => (
                        <Flex gap="4" key={benefit} alignItems="baseline">
                          <Center>
                            <BsX
                              size="1.2rem"
                              fill={
                                plan.planTitle === "Plano Premium"
                                  ? theme.colors.grey[500]
                                  : theme.colors.danger[500]
                              }
                            />
                          </Center>
                          <Text
                            fontSize="md"
                            color={
                              plan.planTitle === "Plano Premium"
                                ? theme.colors.grey[500]
                                : theme.colors.danger[500]
                            }
                          >
                            {benefit}
                          </Text>
                        </Flex>
                      ))}
                    </Grid>
                    {plan.planTitle === "Plano Premium" ? (
                      <Link
                        w="18rem"
                        h="3.32rem"
                        display="flex"
                        target="_blank"
                        rel="noreferrer"
                        color="black.600"
                        bg="secondary.500"
                        borderRadius="4px"
                        alignItems="center"
                        justifyContent="center"
                        m="2rem auto 1rem auto"
                        _hover={{
                          transition: "ease-in 0.2s",
                          color: `${theme.colors.primary[500]}`,
                          bgColor: `${theme.colors.secondary[300]}`,
                        }}
                        href="https://www.app.playdrop.com.br/cadastro"
                      >
                        Assine o {plan.planTitle}
                      </Link>
                    ) : (
                      <Link
                        w="18rem"
                        h="3.32rem"
                        bg="grey.300"
                        display="flex"
                        target="_blank"
                        rel="noreferrer"
                        borderRadius="4px"
                        color="primary.500"
                        alignItems="center"
                        justifyContent="center"
                        m="2rem auto 1rem auto"
                        href="https://www.app.playdrop.com.br/cadastro"
                        _hover={{
                          transition: "ease-in 0.2s",
                          color: `${theme.colors.grey[500]}`,
                          bgColor: `${theme.colors.primary[500]}`,
                        }}
                        border={`2px solid ${theme.colors.primary[500]}`}
                      >
                        Comece com o {plan.planTitle}
                      </Link>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </InView>
      <CommonQuestionsSection currentPage />
    </Grid>
  );
};
