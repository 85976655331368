import iconEdit from "../../assets/images/icons/icon_edit.svg";
import iconShop from "../../assets/images/icons/icon_shop.svg";
import iconCool from "../../assets/images/icons/icon_cool.svg";

import { Button } from "../Reusable/Button";
import { InView } from "react-intersection-observer";
import { Helmet } from "react-helmet-async";
import { SectionData } from "./SectionData";
import { GridContainer } from "../Reusable/GridContainer";
import { Grid, Text, Heading } from "@chakra-ui/react";

export const ECommerceSection = () => {
  return (
    <GridContainer bgColor="grey.200">
      <Helmet>
        <title>PlayDrop - Dropshipping</title>
        <meta name="description" content="Seção de E-commerce da PlayDrop" />
      </Helmet>
      <InView>
        {({ ref, inView }) => (
          <Grid
            w="100%"
            ref={ref}
            overflowY="hidden"
            m={["0", "0", "0", "0 auto"]}
            pt={["0", "0", "0", "6.5rem"]}
            pb={["0", "0", "0", "6.5rem"]}
            transition="transform 1s ease"
            pr={["1rem", "1rem", "1rem", "0"]}
            pl={["1rem", "1rem", "1rem", "0"]}
            transform={`translateY(${inView ? "0" : "100px"})`}
          >
            <Grid
              gap="8"
              w="100%"
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Heading
                color="black.600"
                textAlign="center"
                fontSize={["2xl", "2xl", "2xl", "3xl"]}
              >
                Sua loja completa pronta para vender, saia na frente
              </Heading>
              <Text mb="10" fontSize="md" color="grey.700" textAlign="center">
                O que você está esperando para garantir esse serviço e
                finalmente construir o seu E-commerce rentável investindo pouco?
              </Text>
            </Grid>
            <Grid
              w="100%"
              h="100%"
              m="0 auto"
              maxW="80rem"
              maxH={["100%", "100%", "80%"]}
            >
              <Grid
                gap="6"
                w="100%"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
              >
                <SectionData
                  src={iconEdit}
                  alt="Ícone com lápis"
                  title="Assine a plataforma"
                  text="Para ter acesso a todos os beneficios que irão auxiliar você na realização dos seus objetivos nesse mercado."
                  step={1}
                />
                <SectionData
                  src={iconShop}
                  alt="Ícone de loja"
                  title="Sua loja pronta"
                  text="Sua loja será criada com os nossos temas e você poderá personalizá-la."
                  step={2}
                />
                <SectionData
                  src={iconCool}
                  alt="Ícone de joinha"
                  title="Produtos e Fornecedores"
                  text="Você vai conseguir ter acesso aos melhores produtos, fornecedores, criativos, e precificador de produto."
                  step={3}
                />
              </Grid>
            </Grid>
            <Grid>
              <Button>COMECE GRÁTIS</Button>
            </Grid>
          </Grid>
        )}
      </InView>
    </GridContainer>
  );
};
