export const planData = [
  {
    planId: 1,
    planTitle: "Plano Basic",
    planPrice: 0,
    benefitsIncluded: [
      "Loja de Dropshipping",
      "Produtos",
      "Precificador de Produto",
    ],
    benefitsNotIncluded: [
      "Fornecedores",
      "Produtos em alta",
      "Esteira de Produtos",
      "Gerador de Copy ilimitada",
      "Curso de Dropshipping",
      "Tema Loja Nichada",
      "Loja Personalizada com Mascote 3D",
      "Gerente de Conta Exclusivo",
    ],
  },
  {
    planId: 2,
    planTitle: "Plano Gold",
    planPrice: 89.9,
    benefitsIncluded: [
      "Loja de Dropshipping",
      "Produtos",
      "Fornecedores",
      "Precificador de Produto",
      "Produtos em alta",
      "Esteira de Produtos",
      "Gerador de Copy ilimitada",
      "Curso de Dropshipping",
      "Tema Loja Nichada",
    ],
    benefitsNotIncluded: ["Loja Personalizada com Mascote 3D", "Gerente de Conta Exclusivo"],
  },
  {
    planId: 3,
    planTitle: "Plano Premium",
    planPrice: 139.9,
    benefitsIncluded: [
     "Loja de Dropshipping",
      "Produtos",
      "Fornecedores",
      "Precificador de Produto",
      "Produtos em alta",
      "Esteira de Produtos",
      "Gerador de Copy ilimitada",
      "Curso de Dropshipping",
      "Tema Loja Nichada",
      "Loja Personalizada com Mascote 3D",
      "Gerente de Conta Exclusivo",
      
    ],
  },
];
