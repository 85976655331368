import { theme } from "../../styles";
import { Helmet } from "react-helmet-async";
import { GridContainer } from "../Reusable/GridContainer";
import { Center, Grid, Heading, Image, Text } from "@chakra-ui/react";

import imageExample from "../../assets/images/templates/tema-generico.webp";
import { InView } from "react-intersection-observer";

import ia from "../../assets/images/illustration/ia.jpg";
import temas from "../../assets/images/illustration/temas.jpg";
import produtos from "../../assets/images/illustration/produtos.jpg";
import fornecedores from "../../assets/images/illustration/fornecedores.jpg";
import { Button } from "../Reusable/Button";

export const PlaydropFeatures = () => {
  return (
    <GridContainer bgColor="rgba(128, 166, 255, 0.07)">
      <Helmet>
        <title>PlayDrop - LandingPage - Funcionalidades da PlayDrop</title>
        <meta
          name="description"
          content="Seção de funcionalidades da PlayDrop"
        />
      </Helmet>

      <InView>
        {({ ref, inView }) => (
          <Grid
            w="100%"
            maxW="91rem"
            ref={ref}
            transition="transform 1s ease"
            transform={`translateY(${inView ? "0" : "100px"})`}
            m={["0", "0", "0", "0 auto"]}
            pt={["0", "0", "0", "6.5rem"]}
            pb={["0", "0", "0", "2rem"]}
            pr={["1rem", "1rem", "1rem", "0"]}
            pl={["1rem", "1rem", "1rem", "0"]}
          >
            <Grid
              w="100%"
              maxW="600px"
              m={["0", "0", "0 auto", "0 auto 2rem auto"]}
              pr={["1rem", "1rem", "1rem", "0"]}
              pl={["1rem", "1rem", "1rem", "0"]}
            >
              <Heading
                fontWeight="700"
                color="black.600"
                textAlign="center"
                mb={["6", "6", "6", "0"]}
                fontSize={["2xl", "2xl", "2xl", "3xl"]}
              >
                Funcionalidades da
                <Text ml="2" color="primary.500">
                  PlayDrop
                </Text>
              </Heading>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(500px, 1fr))"
              gridGap="2rem"
            >
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={produtos}
                    alt="Imagem da tela de produtos da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Pare de perder tempo procurando o produto vencedor.
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Na aba de Produtos da PlayDrop, você tem acesso a uma
                    seleção dos melhores produtos já validados, com copy pronta,
                    criativos de alta conversão, público-alvo definido e todas
                    as informações necessárias. Tudo pronto para você começar a
                    vender agora!
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={fornecedores}
                    alt="Imagem da tela de fornecedores da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Chega de Devoluções!
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Sabemos como é frustrante lidar com fornecedores ruins,
                    produtos defeituosos e uma enxurrada de devoluções e
                    reembolsos. Com a PlayDrop, isso acaba agora. Selecionamos a
                    dedo os melhores fornecedores do mercado, internacional e
                    nacional, todos com avaliações impecáveis. Aqui, você vende
                    com confiança, sem se preocupar com trocas ou problemas de
                    qualidade.
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={temas}
                    alt="Imagem da tela de construtor de lojas da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Crie Sua Loja em 10 Minutos!
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Com o Construtor de Lojas da PlayDrop, você pode montar uma
                    loja Shopify incrível com apenas um clique. Escolha um tema
                    de alta conversão, personalize do seu jeito e, em até 10
                    minutos, sua loja estará TOTALMENTE pronta. Diga adeus às
                    horas intermináveis de configuração e dê boas-vindas a uma
                    loja perfeita, criada com facilidade e rapidez.
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={ia}
                    alt="Imagem da tela de gerador de copy da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Você está cansado de perder horas criando copys, gerando
                    nomes para sua loja e definindo o público-alvo perfeito?
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    A Inteligência Artificial da PlayDrop resolve tudo isso com
                    facilidade! Gerar copys irresistíveis para produtos, criar
                    nomes criativos e memoráveis para sua loja, definir personas
                    e públicos-alvo com precisão.
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </InView>
      <Button>COMECE GRÁTIS</Button>
    </GridContainer>
  );
};
