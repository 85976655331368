import {
  Box,
  Link,
  Grid,
  Flex,
  List,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react";

import { theme } from "../../styles";
import { Playdrop } from "../Logo";
import { BsInstagram } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { IoLogoYoutube } from "react-icons/io";
import { FaRegCopyright } from "react-icons/fa";
import { SocialMediaLink } from "../Reusable/SocialMediaLink";
import { TiSocialFacebook } from "react-icons/ti";
import { MdOutlineMarkEmailRead } from "react-icons/md";

export const FooterContent = () => {
  const year = new Date().getFullYear();

  return (
    <Grid
      w="100%"
      as="section"
      bg="primary.550"
      minH={["auto", "auto", "auto", "50vh"]}
    >
      <Grid
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        pt={["10", "10", "10", "8"]}
        pb={["10", "10", "10", "8"]}
        m={["0", "0", "0", "0 auto"]}
        w={["100%", "100%", "100%", "85%"]}
      >
        <Grid
          p="4"
          gridGap="4"
          display="grid"
          alignItems="baseline"
          justifyItems="center"
          gridTemplateColumns={[
            "repeat(auto-fill, minmax(300px, 1fr))",
            "repeat(auto-fill, minmax(240px, 1fr))",
          ]}
        >
          <Flex
            gap="6"
            flexDir="column"
            alignItems={["center", "center", "center", "flex-start"]}
          >
            <Playdrop
              widthLogo="200px"
              colorLogo="#FFFFFF"
              heightLogo="2.88rem"
            />
            <Text
              color="#ffffff"
              textAlign={["center", "center", "center", "left"]}
            >
              A PlayDrop é uma Startup de Dropshipping que atua com foco na
              implementação de um negócio de E-commerce com apenas 01 clique,
              tendo foco naquele dropshipper que está começando no mercado.
            </Text>
          </Flex>
          <Grid gap="6">
            <Heading
              color="grey.400"
              fontSize="1.25rem"
              textAlign={["center", "center", "center", "left"]}
            >
              Navegação
            </Heading>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link
                target="_blank"
                rel="noreferrer"
                color="white.700"
                href="https://www.app.playdrop.com.br"
              >
                Login
              </Link>
            </List>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link
                target="_blank"
                rel="noreferrer"
                color="white.700"
                href="https://www.app.playdrop.com.br/cadastro"
              >
                Criar Conta
              </Link>
            </List>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link
                target="_blank"
                rel="noreferrer"
                color="white.700"
                href="https://www.playdrop.com.br/politica"
              >
                Política de Privacidade
              </Link>
            </List>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link
                target="_blank"
                rel="noreferrer"
                color="white.700"
                href="https://www.playdrop.com.br/termos-de-uso"
              >
                Termos de Uso
              </Link>
            </List>
          </Grid>
          <Grid gap="6">
            <Heading
              color="grey.400"
              fontSize="1.25rem"
              textAlign={["center", "center", "center", "left"]}
            >
              Links Úteis
            </Heading>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link color="white.700">Benefícios</Link>
            </List>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link color="white.700" href="/planos">
                Planos
              </Link>
            </List>
            <List as="li" textAlign={["center", "center", "center", "left"]}>
              <Link color="white.700">Central de Ajuda</Link>
            </List>
          </Grid>
          <Grid gap="6">
            <Heading
              color="grey.400"
              fontSize="1.25rem"
              textAlign={["center", "center", "center", "left"]}
            >
              Contato
            </Heading>
            <List as="li" display="flex" alignItems="center">
              <Center w="2rem" h="2rem">
                <MdOutlineMarkEmailRead
                  size="2rem"
                  fill={`${theme.colors.white[700]}`}
                />
              </Center>
              <Link ml="2" color="white.700">
                atendimento@playdrop.com.br
              </Link>
            </List>
            <List as="li" display="flex" alignItems="center">
              <Center w="2rem" h="2rem">
                <FiPhoneCall size="2rem" color={`${theme.colors.white[700]}`} />
              </Center>
              <Link ml="2" color="white.700">
                (11) 93704-5732
              </Link>
            </List>
          </Grid>
          <Grid gap="6">
            <Heading
              color="grey.400"
              fontSize="1.25rem"
              textAlign={["center", "center", "center", "left"]}
            >
              Redes Sociais
            </Heading>
            <SocialMediaLink
              socialMediaName="Facebook"
              hrefName="https://www.facebook.com/somosplayers"
            >
              <TiSocialFacebook
                fill={`${theme.colors.white[700]}`}
                size="1.5rem"
              />
            </SocialMediaLink>
            <SocialMediaLink
              socialMediaName="Instagram"
              hrefName="https://www.instagram.com/somosplayer/"
            >
              <BsInstagram fill={`${theme.colors.white[700]}`} size="1.2rem" />
            </SocialMediaLink>
            <SocialMediaLink
              socialMediaName="Youtube"
              hrefName="https://www.youtube.com/@somosplayer"
            >
              <IoLogoYoutube
                fill={`${theme.colors.white[700]}`}
                size="1.2rem"
              />
            </SocialMediaLink>
          </Grid>
        </Grid>
        <Box mt="8" mb="8" w="100%" h="1.5px" bg="primary.250" />
        <Flex
          w="100%"
          alignItems="center"
          flexDir={["column", "column", "row"]}
          justifyContent={["center", "center", "space-between"]}
        >
          <Flex alignItems="center">
            <Center>
              <FaRegCopyright fill="#ffffff" />
            </Center>
            <Text fontSize="sm" color="white.700">
              {year} PlayDrop. Todos os direitos reservados.
            </Text>
          </Flex>
          <Text fontSize="sm" color="white.700">
            CNPJ: 46.745.013.0001-90
          </Text>
        </Flex>
      </Grid>
    </Grid>
  );
};
