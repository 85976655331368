import { pulse } from "../../keyframes";
import { InView } from "react-intersection-observer";

import {
  Box,
  Grid,
  Link,
  Text,
  Flex,
  Heading,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";

import iconOne from "../../assets/images/illustration/LP-ALTO.svg";
import image from "../../assets/images/illustration/circulo-lp.svg";

export const InicialSection = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Grid
      w="100%"
      mt="6.3rem"
      as="section"
      bg="primary.500"
      overflowX="hidden"
      position="relative"
    >
      <Grid w="100%" h="100%">
        <Image
          src={iconOne}
          alt="Ícone de ilustração"
          position="absolute"
          top="0"
          left="0"
        />
      </Grid>
      <InView>
        {({ ref, inView }) => (
          <Flex
            w="100%"
            p="1rem"
            ref={ref}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            transition="transform 1s ease"
            transform={
              isWideVersion
                ? `translateX(${inView ? "0" : "-100%"})`
                : `translateX(${inView ? "0" : "-100px"})`
            }
          >
            <Grid
              w="100%"
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                mb="6"
                h="2.3rem"
                mt="3.5rem"
                w="16.38rem"
                fontSize="md"
                bg="primary.300"
                color="grey.400"
                p="0.3rem 1.25rem"
                textAlign="center"
                borderRadius="0.25rem"
              >
                É hora de mudar de vida
              </Box>

              <Heading
                as="h1"
                w="100%"
                maxW="50rem"
                color="grey.400"
                fontSize={["6xl", "5xl"]}
                fontWeight="700"
                textAlign="center"
              >
                Sua loja pronta de Dropshipping. Comece agora mesmo GRÁTIS!
              </Heading>

              <Text
                w="100%"
                mb="6"
                mt="6"
                maxW="50.4rem"
                color="grey.400"
                fontSize="xl"
                textAlign="center"
              >
                Lojas Profissionais Personalizadas, Produtos Validados,
                Precificador de Produto, Criativos e Fornecedores Exclusivos.
              </Text>
              <Link
                mt="4"
                mb="10"
                h="3.5rem"
                w="20rem"
                fontSize="md"
                fontWeight="500"
                display="flex"
                target="_blank"
                rel="noreferrer"
                color="black.500"
                bg="secondary.500"
                alignItems="center"
                borderRadius="1rem"
                justifyContent="center"
                _hover={{ opacity: 0.7 }}
                transition="ease-in-out 0.2s"
                animation={`${pulse} 2000ms infinite`}
                href="https://www.app.playdrop.com.br/cadastro"
              >
                COMEÇAR GRÁTIS
              </Link>
            </Grid>
          </Flex>
        )}
      </InView>
    </Grid>
  );
};
