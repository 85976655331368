import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Helmet } from "react-helmet-async";
import { Box, Center, Flex, Grid, Image } from "@chakra-ui/react";
import { InicialSection } from "../../components/InicialSection";
import { ProductsSection } from "../../components/ProductsSection";
import { FeedbackSection } from "../../components/FeedbackSection";
// import { ServicesSection } from "../../components/ServicesSection";
import { TemplatesSection } from "../../components/TemplatesSection";
import { ECommerceSection } from "../../components/ECommerceSection";
import { DiferentialsSection } from "../../components/DiferentialsSection";
import { CommonQuestionsSection } from "../../components/CommonQuestionsSection";

import { RxPinTop } from "react-icons/rx";
// import { FindSuppliersSection } from "../../components/FindSuppliersSection";
// import { CreateOnlineStoreSection } from "../../components/CreateOnlineStoreSection";
// import { StandOutFromTheCrowdSection } from "../../components/StandOutFromTheCrowdSection";
// import { ArtificialIntelligenceSection } from "../../components/ArtificialIntelligenceSection";

import iconTwo from "../../assets/images/illustration/LP-BAIXO.svg";
import { PlaydropFeatures } from "../../components/PlaydropFeatures";

const vimeoUrl =
  "https://player.vimeo.com/video/987980924?h=d16a27df50&autoplay=1&loop=1&muted=1&background=1";

export const LandingPage = () => {
  const scrollToTop = () => {
    return window.scrollTo(0, 0);
  };

  return (
    <Grid w="100%" minH="100vh" as="main" position="relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PlayDrop LandingPage</title>
        <meta
          name="description"
          content="A PlayDrop é uma Startup de Dropshipping que atua com foco na implementação de um E-commerce tendo foco naquele dropshipper que está iniciando no mercado"
        />
      </Helmet>
      <Header />
      <InicialSection />

      <Grid w="100%" position="relative">
        <Box w="100%" h="239.0625px" bg="primary.500" />

        <Image
          src={iconTwo}
          alt="Ícone de ilustração"
          position="absolute"
          top="-2rem"
          left="0"
        />

        <Flex
          w="100%"
          h="100%"
          pl="1rem"
          pr="1rem"
          maxW="850px"
          maxH="500px"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          position="absolute"
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
            }}
            title="vimeo-player"
            src={vimeoUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        </Flex>

        <Box w="100%" h="239.0625px" bg="grey.300" />
      </Grid>

      {/* <ServicesSection /> */}

      {/* <StandOutFromTheCrowdSection /> */}

      <ProductsSection />

      <ECommerceSection />

      <PlaydropFeatures />

      {/* <FindSuppliersSection /> */}

      <TemplatesSection />

      <FeedbackSection />

      <DiferentialsSection />

      {/* <CreateOnlineStoreSection /> */}

      {/* <ArtificialIntelligenceSection /> */}

      <CommonQuestionsSection />

      <Footer />

      <Center
        p="4"
        right="6"
        as="button"
        bottom="6rem"
        bg="#3058A5"
        position="fixed"
        fontWeight="bold"
        borderRadius="50%"
        _hover={{ opacity: 0.7 }}
        onClick={() => scrollToTop()}
      >
        <RxPinTop color="#fff" size="2rem" />
      </Center>
    </Grid>
  );
};
