import { InView } from "react-intersection-observer";
import { Helmet } from "react-helmet-async";
import { GridContainer } from "../Reusable/GridContainer";
import { FiCornerUpRight } from "react-icons/fi";
import { Box, Grid, Heading, Image, Text } from "@chakra-ui/react";

import imgMulher from "../../assets/images/illustration/image-footer.webp";
import { Button } from "../Reusable/Button";

export const DiferentialsSection = () => {
  return (
    <GridContainer bgColor="white.600">
      <Helmet>
        <title>PlayDrop</title>
        <meta
          name="description"
          content="Seção de diferenciais da plataforma PlayDrop"
        />
      </Helmet>
      <Grid
        w="100%"
        m={["0", "0", "0", "0 auto"]}
        pt={["0", "0", "0", "6.5rem"]}
        pb={["0", "0", "0", "6.5rem"]}
        pr={["1rem", "1rem", "1rem", "0"]}
        pl={["1rem", "1rem", "1rem", "0"]}
      >
        <InView>
          {({ ref, inView }) => (
            <Grid
              ref={ref}
              transition="transform 1s ease"
              transform={`translateY(${inView ? "0" : "100px"})`}
            >
              <Grid
                w="100%"
                m="0 auto"
                maxW="80rem"
                display="grid"
                gridGap="1.5rem"
                gridTemplateColumns={["1fr", "1fr", "1fr 2fr"]}
              >
                <Grid
                  w={["100%", "100%", "90%"]}
                  bg="primary.500"
                  gap="1rem"
                  display="flex"
                  flexDir="column"
                  borderRadius="10px"
                  justifyContent="space-between"
                >
                  <Grid p="5rem 2rem 0 2rem">
                    <Heading as="h4" fontSize="2.5rem" color="#fff">
                      O plano basic da PlayDrop é
                      <Text color="secondary.500" fontSize="3rem">
                        {" "}
                        gratuito e vitalício
                      </Text>
                    </Heading>

                    <a
                      href="https://app.playdrop.com.br/cadastro"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.4rem",
                      }}
                    >
                      <Box>
                        <FiCornerUpRight size="2rem" color="#fff" />
                      </Box>

                      <Text color="#fff" fontSize="0.8rem" fontWeight="500">
                        Construa sua loja sem pagar nada
                      </Text>
                    </a>

                    <Box m="2rem auto" position="relative">
                      <Box
                        bg="secondary.500"
                        top="0"
                        width="200px"
                        h="2.6rem"
                        position="absolute"
                        borderRadius="8% 92% 14% 86% / 100% 0% 100% 0%"
                      />
                      <Box
                        w="200px"
                        h="2.6rem"
                        bg="white.500"
                        color="grey.900"
                        fontSize="1.25rem"
                        fontWeight="500"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        transform="rotate(-6deg)"
                        borderRadius="8% 92% 14% 86% / 100% 0% 100% 0%"
                      >
                        FREE
                      </Box>
                    </Box>
                  </Grid>

                  <Image
                    w="100%"
                    m="0"
                    src={imgMulher}
                    alt="Imagem ilustrativa de uma mulher"
                  />
                </Grid>

                <Grid
                  w="100%"
                  display="grid"
                  gridGap="1rem"
                  gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                >
                  <Grid
                    p="1.5rem"
                    w="100%"
                    bg="primary.100"
                    gap="1rem"
                    display="flex"
                    flexDir="column"
                    alignItems="flex-start"
                    borderRadius="10px"
                    justifyContent="center"
                  >
                    <Heading as="h4" color="grey.900" fontSize="2.7rem">
                      Biblioteca de{" "}
                      <Text color="primary.500" fontSize="2.7rem">
                        {" "}
                        Produtos Quentes
                      </Text>
                    </Heading>
                  </Grid>

                  <Grid
                    p="1.5rem"
                    w="100%"
                    bg="primary.100"
                    gap="1rem"
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    borderRadius="10px"
                    justifyContent="center"
                  >
                    <Heading as="h4" color="grey.900" fontSize="2.7rem">
                      Temas que
                      <Text color="primary.500" fontSize="2.7rem">
                        {" "}
                        aumentam suas vendas
                      </Text>
                    </Heading>
                  </Grid>

                  <Grid
                    p="1.5rem"
                    w="100%"
                    bg="primary.100"
                    gap="1rem"
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    borderRadius="10px"
                    justifyContent="center"
                  >
                    <Heading as="h4" color="grey.900" fontSize="2.7rem">
                      Organize a sua operação com a
                      <Text color="primary.500" fontSize="2.7rem">
                        esteira de produtos
                      </Text>
                    </Heading>
                  </Grid>
                  <Grid
                    p="2rem 2rem 2rem 1.5rem"
                    w="100%"
                    bg="primary.100"
                    gap="1rem"
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    borderRadius="10px"
                    justifyContent="center"
                  >
                    <Heading as="h4" color="grey.900" fontSize="2.7rem">
                      Recursos que
                      <Text color="primary.500" fontSize="2.7rem">
                        facilitam a sua operação
                      </Text>
                    </Heading>

                    <a
                      href="https://app.playdrop.com.br/cadastro"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.4rem",
                      }}
                    >
                      <Box>
                        <FiCornerUpRight size="2rem" color="#4f4f4f" />
                      </Box>

                      <Text color="gray.800" fontSize="1rem" fontWeight="500">
                        Gere nomes, preços, copys e muito mais...
                      </Text>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </InView>

        <Button>COMECE GRÁTIS</Button>
      </Grid>
    </GridContainer>
  );
};
